
function AjaxFormCompleteFunction(ajaxContext, formID) {
    $("#" + formID)[0].validationCallbacks = [];
    $clearHandlers($("#" + formID)[0]);
    //var newDiv = document.createElement("div");
    //$(newDiv).html(ajaxContext.get_data());
    //var forms = newDiv.getElementsByTagName(formID);
    var forms = ajaxContext.forms;
    $("#" + formID).html(forms[0].innerHTML);
    $("#" + formID)[0].action = forms[0].action;
    Sys.Mvc.FormContext._Application_Load();
    return false;
}

//To set active link on page reload
$(document).ready(function () {
    $('.nav li a').click(function (e) {
        $('.nav li a').removeClass('active');
        var $this = $(this);
        if (!$this.hasClass('active')) {
            $this.addClass('active');
        }
    });
});

$(function ($) {
    let url = window.location.href;
    $('.nav li a').removeClass('active')[0];
    $('li a').each(function () {
        if (this.href === url) {
            $(this).closest('li a').addClass('active');
        }
    });
});

function fnGotoHowItWorks() {
    $('html, body').animate({
        scrollTop: $(".how_it_works").offset().top
    }, 0);
}


// To Get Token 
function GetToken() {
    var tokenValue = "";

    tokenWindow = this && typeof this === typeof window ? this : window;
    var tokenName = "__RequestVerificationToken";
    var inputElements = tokenWindow.document.getElementsByTagName("input");
    for (var i = 0; i < inputElements.length; i++) {
        var inputElement = inputElements[i];
        if (inputElement.type === "hidden" && inputElement.name === tokenName) {
            tokenValue = $(inputElement).val();
            break;
        }
    }

    return tokenValue;
}
